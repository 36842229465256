import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Icon } from '@intelligenceindustrielle/react-ui-components';
import DraggableCauseIconContainer from '~components/UI/RoundButton/DraggableCauseIconContainer';
import reduxOperations from '~services/reduxOperations';
import { AddIcon, StopCauseIcon } from '~UI/RoundButton/RoundIcons';
import { FontAwesome } from '~UI';
import StopCauseForm from './StopCauseForm';
import './StopCausesForm.scss';

const SUB_MENU_LIMIT = 100;

const StopCauseEdition = ({ goBackForm, stopcause, machineId }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const featureToggles = useSelector(state => state.settings.settings.featureToggles);

  const [editedStopCause, setEditedStopCause] = useState(null);
  const [isInSubMenu, setIsInSubMenu] = useState(false);
  const [subStopCauses, setSubStopCauses] = useState(stopcause.subMenu || []);
  const [selectedSubStopCause, setSelectedSubStopCause] = useState(null);
  const [selectedSubSubStopCause, setSelectedSubSubStopCause] = useState(null);
  const [activeStopCause, setActiveStopCause] = useState(stopcause);

  const handleSubSubStopCauseClick = subSubStopCause => {
    setSelectedSubSubStopCause(subSubStopCause);
    setActiveStopCause(subSubStopCause);
  };

  const handleSubStopCauseClick = subStopCause => {
    setSelectedSubStopCause(subStopCause);
    setActiveStopCause(subStopCause);
  };

  useEffect(() => {
    setSubStopCauses(stopcause.subMenu || []);
    const foundSubStopCause = stopcause.subMenu.find(sc => sc.id === selectedSubStopCause?.id);
    if (foundSubStopCause) {
      setSelectedSubStopCause(foundSubStopCause);
      const foundSubSubStopCause = foundSubStopCause.subMenu?.find(sc => sc.id === selectedSubSubStopCause?.id);
      if (foundSubSubStopCause) {
        setSelectedSubSubStopCause(foundSubSubStopCause);
      } else {
        setSelectedSubSubStopCause(null);
      }
    } else {
      setSelectedSubStopCause(null);
    }
  }, [stopcause, selectedSubStopCause?.id, selectedSubSubStopCause?.id]);

  const handleStopcauseSelection = (editedStopCauseArg, isInSubMenuArg, parent) => {
    if (parent) {
      setActiveStopCause(parent);
    }
    setEditedStopCause(editedStopCauseArg);
    if (typeof isInSubMenuArg === 'boolean') {
      setIsInSubMenu(isInSubMenuArg);
    }
  };

  const updateSubStopCauses = newSubStopCauses => {
    dispatch(reduxOperations.machines
      .updateStopCause(machineId, stopcause.id, { ...stopcause, subMenu: newSubStopCauses }));
  };

  const updateSubSubSubStopCauses = (subStopCause, newSubSubStopCauses) => {
    if (subStopCause.id === selectedSubStopCause.id) {
      return {
        ...subStopCause,
        subMenu: subStopCause.subMenu.map(subSubStopCause => {
          if (subSubStopCause.id === selectedSubSubStopCause.id) {
            return {
              ...selectedSubSubStopCause,
              subMenu: newSubSubStopCauses,
            };
          }
          return subSubStopCause;
        }),
      };
    }
    return subStopCause;
  };

  if (editedStopCause) {
    return (
      <StopCauseForm
        firstStopCause={activeStopCause}
        stopcause={editedStopCause}
        isInSubMenu={isInSubMenu}
        goBackEdition={() => handleStopcauseSelection(null)}
        goBackForm={goBackForm}
        machineId={machineId}
      />
    );
  }

  return (
    <div className="StopCauseEdition">
      <div className="flexSpaceBetween">
        <FontAwesome
          icon="arrow-left"
          className="backButtonArrow"
          style={{ marginLeft: '0px', cursor: 'pointer', marginTop: '4px', fontSize: '20px' }}
          onClick={goBackForm}
        />
        <span style={{ fontSize: '20px' }}>
          <Icon
            icon="info-circle"
            tooltipLabel={t('stopCauseInfo')}
          />
        </span>
      </div>
      <div className="centered">
        <StopCauseIcon
          option={{ ...stopcause, badgeCount: stopcause.subMenu?.length }}
          onClick={() => {
            if (selectedSubStopCause) {
              setSelectedSubStopCause(null);
            } else {
              handleStopcauseSelection(stopcause, false);
            }
          }}
        />
      </div>
      <hr />
      {selectedSubStopCause ? (
        <div className="centered">
          <StopCauseIcon
            option={{ ...selectedSubStopCause, badgeCount: selectedSubStopCause.subMenu?.length }}
            onClick={() => {
              if (selectedSubSubStopCause) {
                setSelectedSubSubStopCause(null);
              } else {
                handleStopcauseSelection(selectedSubStopCause, true, stopcause);
              }
            }}
          />
        </div>
      ) : (
        <>
          <h2>{t('subMenu')}</h2>
          <div className="StopCausesIconsContainer" style={{ justifyContent: 'left' }}>
            <DndProvider backend={HTML5Backend}>
              <DraggableCauseIconContainer
                causesArray={subStopCauses}
                onClick={sc => {
                  if (featureToggles?.features?.nestedStopCause) {
                    handleSubStopCauseClick(sc);
                  } else {
                    handleStopcauseSelection(sc, true, stopcause);
                  }
                }}
                updateCauses={updateSubStopCauses}
              >
                {(stopcause?.subMenu || []).length < SUB_MENU_LIMIT && (
                  <AddIcon
                    onClick={() => handleStopcauseSelection({}, true, stopcause)}
                  />
                )}
              </DraggableCauseIconContainer>
            </DndProvider>
          </div>
        </>
      )}
      {selectedSubStopCause && (
        selectedSubSubStopCause ? (
          <>
            <hr />
            <div className="centered">
              <StopCauseIcon
                option={{ ...selectedSubSubStopCause, badgeCount: selectedSubSubStopCause.subMenu?.length }}
                onClick={() => {
                  if (selectedSubSubStopCause) {
                    handleStopcauseSelection(selectedSubSubStopCause, true, selectedSubStopCause);
                  } else {
                    selectedSubSubStopCause(null);
                  }
                }}
              />
            </div>
          </>
        ) : (
          <>
            <hr />
            <h2>{`${t('subMenu')} (2)`}</h2>
            <div className="StopCausesIconsContainer" style={{ justifyContent: 'left' }}>
              <DndProvider backend={HTML5Backend}>
                <DraggableCauseIconContainer
                  causesArray={selectedSubStopCause.subMenu || []}
                  onClick={handleSubSubStopCauseClick}
                  updateCauses={newSubSubStopCauses => {
                    const updatedSubStopCauses = subStopCauses.map(
                      sc => (sc.id === selectedSubStopCause.id ? { ...sc, subMenu: newSubSubStopCauses } : sc),
                    );
                    updateSubStopCauses(updatedSubStopCauses);
                  }}
                >
                  {(selectedSubStopCause.subMenu || []).length < SUB_MENU_LIMIT && (
                    <AddIcon
                      onClick={() => handleStopcauseSelection({}, true, selectedSubStopCause)}
                    />
                  )}
                </DraggableCauseIconContainer>
              </DndProvider>
            </div>
          </>
        )
      )}
      {selectedSubSubStopCause && selectedSubStopCause && (
        <>
          <hr />
          <h2>{`${t('subMenu')} (3)`}</h2>
          <div className="StopCausesIconsContainer" style={{ justifyContent: 'left' }}>
            <DndProvider backend={HTML5Backend}>
              <DraggableCauseIconContainer
                causesArray={selectedSubSubStopCause.subMenu || []}
                onClick={sc => handleStopcauseSelection(sc, true, selectedSubSubStopCause)}
                updateCauses={newSubSubSubStopCauses => {
                  const updatedSubStopCauses = subStopCauses.map(
                    sc => updateSubSubSubStopCauses(sc, newSubSubSubStopCauses),
                  );
                  updateSubStopCauses(updatedSubStopCauses);
                }}
              >
                {(selectedSubSubStopCause.subMenu || []).length < SUB_MENU_LIMIT && (
                  <AddIcon
                    onClick={() => handleStopcauseSelection({}, true, selectedSubSubStopCause)}
                  />
                )}
              </DraggableCauseIconContainer>
            </DndProvider>
          </div>
        </>
      )}
    </div>
  );
};

StopCauseEdition.propTypes = {
  goBackForm: PropTypes.func.isRequired,
  stopcause: PropTypes.shape({
    id: PropTypes.string,
    subMenu: PropTypes.arrayOf(PropTypes.object),
  }),
  machineId: PropTypes.string.isRequired,
};
StopCauseEdition.defaultProps = {
  stopcause: {},
};

export default StopCauseEdition;
