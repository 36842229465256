import React, { useRef, useEffect } from 'react';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api.js';
import { SubmitButton } from '~components/UI';
import './CodeEditor.scss';

interface HTMLEditorProps {
  html: string;
  onSubmit?: (data: string) => void;
}

export const JSONEditor: React.FC<HTMLEditorProps> = ({ html, onSubmit }) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const editorInstanceRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);

  const handleSubmit = () => {
    if (editorInstanceRef.current && onSubmit) {
      onSubmit(editorInstanceRef.current.getValue());
    }
  };

  useEffect(() => {
    if (editorRef.current) {
      editorInstanceRef.current = monaco.editor.create(editorRef.current, {
        value: html,
        language: 'html',
        theme: 'vs-dark',
        minimap: { enabled: false },
        lineNumbersMinChars: 3,
      });
    }

    const handleWindowResize = () => {
      editorInstanceRef.current?.layout();
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      editorInstanceRef.current?.dispose();
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [html]);

  return (
    <div className="htmlEditor">
      <div ref={editorRef} className="htmlEditor__editor" />
      {onSubmit && (
        <div className="htmlEditor__buttons">
          <SubmitButton onClick={handleSubmit} label="Soumettre le HTML" />
        </div>
      )}
    </div>
  );
};

export default JSONEditor;
