import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { getSocket } from '~services/socket';
import API from '~services/endpoints';
import { RootState } from '~services/store';

function PromptWidgetVariables({
  h,
  w,
  widget,
}) {
  const socket = getSocket();
  const variables = useSelector((state: RootState) => state.variables);
  const [randomUUID] = useState(uuidv4());
  const [completeUrl, setCompleteUrl] = useState('');

  const handleSocketData = data => {
    socket?.emit('updateStatistics', { uuid: randomUUID, statistics: data }); // TODO: change to updateVariables
  };

  const fetchValues = async () => {
    const wait = ms => new Promise(resolve => {
      setTimeout(resolve, ms);
    });
    const { values } = await API.getMultiplesValues(variables.map(variable => variable.id));

    const data = variables.map(variable => {
      const value = values.find(v => v.valueId === variable.id);
      return {
        id: variable.id,
        name: variable.variable,
        value: value ? value.value : null,
      };
    });

    await wait(1000); // wait 1 second for the iframe to load
    data.forEach(d => {
      socket?.emit('updateStatistics', { uuid: randomUUID, statistics: d }); // TODO: change to updateVariables
    });
  };

  useEffect(() => {
    const baseUrl = process.env.NODE_ENV === 'production' ? `https://${window.location.hostname}` : 'http://localhost:3000';
    const newCompleteUrl = `${widget.url}?url=${baseUrl}&channel=${randomUUID}`;
    setCompleteUrl(newCompleteUrl);

    async function sendFirstData() {
      await fetchValues();
      socket?.on('value', handleSocketData);
    }

    sendFirstData();

    return () => {
      socket?.removeListener('value', handleSocketData);
    };
  }, [widget.url, randomUUID, socket, variables]);

  return (
    <iframe
      style={{
        border: 0,
        height: h,
        width: w,
        position: 'absolute',
        top: 0,
        left: 0,
      }}
      title="promptWidgetVariables"
      scrolling="auto"
      src={completeUrl}
    >
      <p>Your browser does not support iframes</p>
    </iframe>
  );
}

PromptWidgetVariables.propTypes = {
  h: PropTypes.number.isRequired,
  w: PropTypes.number.isRequired,
  widget: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default PromptWidgetVariables;
