import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import IdleTimer from 'react-idle-timer';
import { v4 as uuidv4 } from 'uuid';
import { useShift } from '~utils/hooks';
import API from '~services/endpoints';
import { getDataRangeStartEnd } from '~utils/time';
import { FETCH_STATS_DELAY_MS } from '~utils/constants';
import { getSocket } from '~services/socket';

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift();
}

function PromptWidgetMachine({
  h,
  w,
  widget,
}) {
  const [currentShift] = useShift(widget.machineId);
  const idleTimerRef = useRef(null);
  const socket = getSocket();
  const [randomUUID] = useState(uuidv4());

  const [completeUrl, setCompleteUrl] = useState('');

  useEffect(() => {
    let token = getCookie('token') || localStorage.getItem('token');
    if (!token) {
      console.error('No token found. Use a default value or handle the error.');
      token = 'default_token_for_debugging';
    }

    const baseUrl = process.env.NODE_ENV === 'production' ? `https://${window.location.hostname}` : 'http://localhost:3000';
    setCompleteUrl(`${widget.url}?url=${baseUrl}&channel=${randomUUID}&token=${token}`);
  }, [widget.url, randomUUID]);

  const { data: statistics, refetch } = useQuery({
    queryKey: ['fetchWidgetMachineStats', widget.machineId, widget.currentOperation, widget.currentSKUNumber, widget.currentWorkOrder, widget.intervalType],
    queryFn: () => {
      const intervalType = widget.intervalType || 'shift';
      if (!widget.machineId) {
        return null;
      }

      const filter = {
        currentOperation: widget.currentOperation,
        currentSKUNumber: widget.currentSKUNumber,
        currentWorkOrder: widget.currentWorkOrder,
      };
      if (intervalType !== 'always') {
        const { start, end } = getDataRangeStartEnd(intervalType, currentShift);
        filter.timestamp = {
          $gt: start,
          $lt: end,
        };
      }

      return API.getMachineStats(widget.machineId, filter);
    },
    refetchInterval: FETCH_STATS_DELAY_MS,
    keepPreviousData: true,
  });

  const handleOnActive = () => {
    refetch();
  };

  const handleOnIdle = () => {
    refetch();
    if (idleTimerRef.current) {
      idleTimerRef.current.reset();
    }
  };

  useEffect(() => {
    if (statistics && socket) {
      socket.emit('updateStatistics', { uuid: randomUUID, statistics });
    }
  }, [statistics, socket, randomUUID]);

  return (
    <>
      <IdleTimer
        ref={idleTimerRef}
        timeout={1000 * 90}
        onActive={handleOnActive}
        onIdle={handleOnIdle}
        debounce={250}
      />
      <iframe
        style={{
          border: 0,
          height: h,
          width: w,
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        title="promptWidgetMachine"
        scrolling="auto"
        src={completeUrl}
      >
        <p>Your browser does not support iframes</p>
      </iframe>
    </>
  );
}

PromptWidgetMachine.propTypes = {
  h: PropTypes.number.isRequired,
  w: PropTypes.number.isRequired,
  widget: PropTypes.shape({
    machineId: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    currentOperation: PropTypes.bool,
    currentSKUNumber: PropTypes.bool,
    currentWorkOrder: PropTypes.bool,
    intervalType: PropTypes.string,
  }).isRequired,
};

export default PromptWidgetMachine;
