import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import { useTranslation } from 'react-i18next';
import { MachineEventPopup, PerformanceEventPopup, DefectEventPopup, MachineOperatorPopup } from '~UI';
import { getMachineStateInformations } from '~components/MachineState';
import API from '~services/endpoints';
import { getAlives } from '~services/alives/endpoints';
import { getSocket } from '~services/socket';
import { reducersTypes } from '~services';
import { invertColor, getTitle } from '~utils';
import { formatDuration, serverTime } from '~utils/time';
import { getMachineStateWidgetSize } from '~utils/responsiveValue';
import Tick from '~utils/Tick';
import JsxWidget from '../BaseWidgets/JsxWidget';
import { useShift } from '~utils/hooks';
import { MachineParameter } from './MachineParameter';
import './MachineStateWidget.scss';

const FIVE_MINUTES = 1000 * 60 * 5;

const MachineStateWidget = ({
  h, w, x, y, widget,
}) => {
  const { t } = useTranslation();
  const machines = useSelector(state => state.machines);
  const settings = useSelector(state => state.settings.settings);
  const isInConfigurationMode = useSelector(state => state.views.isInConfigurationMode);
  const showAlive = useSelector(state => state.settings.settings.featureToggles.features.showAlive);
  const featureToggles = useSelector(state => state.settings.settings.featureToggles);
  const language = useSelector(state => state.views.language);

  const title = featureToggles.features?.tileTranslations ? getTitle(widget, language) : widget?.title || '';

  const [currentShift] = useShift(widget.machineId);

  const machine = machines.find(m => m.id === widget.machineId);

  const [cancelAlives, setCancelAlives] = useState(null);
  const [cancelEvents, setCancelEvents] = useState(null);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [lastAlive, setLastAlive] = useState(null);
  const [currentTS, setCurrentTS] = useState(serverTime());
  const [cancelPerformanceEvents, setCancelPerformanceEvents] = useState(null);
  const [machineParams, setMachineParams] = useState({});
  const [currentMachine, setCurrentMachine] = useState(machine);
  const [showDowntimePopup, setShowDowntimePopup] = useState(false);
  const [downtimePopupEvent, setDowntimePopupEvent] = useState(null);
  const [showPerformancePopup, setShowPerformancePopup] = useState(false);
  const [performancePopupEvent, setPerformancePopupEvent] = useState(null);
  const [showDefectPopup, setShowDefectPopup] = useState(false);
  const [defectPopupEvent, setDefectPopupEvent] = useState(null);
  const [showMachineOperatorPopup, setShowMachineOperatorPopup] = useState(false);
  const [operatorPopupEvent, setOperatorPopupEvent] = useState(null);
  const [hasUnmounted, setHasUnmounted] = useState(false);

  const idleTimerRef = useRef(null);
  const currentEventRef = useRef(currentEvent);
  const downtimePopupEventRef = useRef(downtimePopupEvent);

  const fetchCurrentEvent = async () => {
    const filter = {
      type: 'MachineStatus',
      machineId: widget.machineId,
      timestamp: {
        $lte: currentTS,
      },
    };
    const sort = { timestamp: -1 };
    const { events: newEvents } = await API.getEvents(filter, sort, 1);
    if (!hasUnmounted) {
      const curEvent = newEvents[0];
      setCurrentEvent(curEvent);
      setCancelEvents(undefined);
      setMachineParams(prevParams => ({
        ...prevParams,
        operation: curEvent?.operation,
        operator: curEvent?.operator,
        workOrder: curEvent?.workOrder,
        skuNumber: curEvent?.skuNumber,
      }));
    }
    setCancelEvents(true);
  };

  const fetchCurrentPerformanceEvent = async () => {
    const filter = {
      type: 'PerformanceEvent',
      machineId: widget.machineId,
      timestamp: {
        $lte: currentTS,
      },
    };
    const sort = { timestamp: -1 };
    const { events: newEvents } = await API.getEvents(filter, sort, 1);
    if (!hasUnmounted) {
      if (newEvents.length) {
        setPerformancePopupEvent(newEvents[0]);
        setCancelPerformanceEvents(undefined);
      }
    }
    setCancelPerformanceEvents(true);
  };

  const fetchLastAlive = async () => {
    if (showAlive && machine) {
      const filter = {};
      const sort = { timestamp: -1 };
      const { alives: newAlives } = await getAlives(machine.streamId, filter, sort, 1, 'stream');
      if (!hasUnmounted) {
        setLastAlive(newAlives[0]);
        setCancelAlives(undefined);
      }
      setCancelAlives(true);
    }
  };

  const handleSocketAlive = alive => {
    if (currentMachine && alive.streamId === currentMachine.streamId) {
      setLastAlive(alive);
    }
  };

  const handleSocketEvent = event => {
    if (event.machineId === widget.machineId) {
      if (event.type === 'MachineStatus') {
        if (!currentEventRef.current
          || event.id === currentEventRef.current.id
          || event.timestamp > (currentEventRef.current.timestamp)) {
          setCurrentEvent(event);

          if (event.timestamp > (lastAlive)) {
            setLastAlive(event.timestamp);
          }

          if (event.status === 'OFF' && widget.checkboxPopup) {
            setDowntimePopupEvent(event);
            setShowDowntimePopup(false);
          }

          if (widget.checkboxPopup
            && (event.status === (widget.showPopupAtEventStatus || 'ON') || widget.showPopupAtEventStatus === 'BOTH')
            && !event.stopCauseId
            && (widget.showPopupAtEventStatus === 'ON' ? downtimePopupEventRef.current && !downtimePopupEventRef.current.stopCauseId : true)) {
            setShowDowntimePopup(true);
          }
        }
      }

      if (event.type === 'PerformanceEvent' && !event.performanceCauseId) {
        if (event.status === 'START') {
          if (widget.showPerformanceCausePopupAtEventStatus === 'START') {
            setPerformancePopupEvent(event);
            setShowPerformancePopup(true);
          } else {
            setPerformancePopupEvent(event);
            setShowPerformancePopup(false);
          }
        } else if (event.status === 'END' && widget.showPerformanceCausePopupAtEventStatus === 'END') {
          setShowPerformancePopup(true);
        }
      }

      if (event.type === 'PartEvent' && event.eventType === 'SCRAP' && !event.defectCauseId) {
        if (widget.showDefectCausePopupAtEventStatus === 'SCRAP') {
          setDefectPopupEvent(event);
          setShowDefectPopup(true);
        } else {
          setDefectPopupEvent(event);
          setShowDefectPopup(false);
        }
      }
    }
  };

  const handleSocketMachines = message => {
    if (message.type === 'MACHINE_PARAM_UPDATE' && message.machineId === widget.machineId) {
      setMachineParams(message.params);
    }
  };

  const handleSocketEventDeleted = event => {
    if (event.type === 'MachineStatus' && event.machineId === widget.machineId && currentEvent.id === event.id) {
      fetchCurrentEvent();
    }
  };

  const attachEventListeners = socket => {
    socket?.on('alive', handleSocketAlive);
    socket?.on('event', handleSocketEvent);
    socket?.on('eventDeleted', handleSocketEventDeleted);
    socket?.on('machines', handleSocketMachines);
  };

  const removeEventListeners = socket => {
    socket?.removeListener('alive', handleSocketAlive);
    socket?.removeListener('event', handleSocketEvent);
    socket?.removeListener('eventDeleted', handleSocketEventDeleted);
    socket?.removeListener('machines', handleSocketMachines);
  };

  const updateTime = () => {
    setCurrentTS(serverTime());
  };

  useEffect(() => {
    const socket = getSocket();
    fetchCurrentEvent();
    fetchCurrentPerformanceEvent();
    fetchLastAlive();
    Tick.subscribe(updateTime, 1);
    attachEventListeners(socket);

    return () => {
      removeEventListeners(socket);
      if (cancelAlives) {
        setCancelAlives();
      }
      if (cancelEvents) {
        setCancelEvents();
      }
      if (cancelPerformanceEvents) {
        setCancelPerformanceEvents();
      }
      Tick.unsubscribe(updateTime);
      setHasUnmounted(true);
    };
  }, []);

  useEffect(() => {
    const newMachine = machines.find(m => m.id === widget.machineId);
    setCurrentEvent(null);
    setLastAlive(null);
    setCurrentMachine(newMachine);
    setMachineParams(newMachine && newMachine.params);

    fetchCurrentEvent();
    fetchCurrentPerformanceEvent();
    fetchLastAlive();
  }, [widget.machineId]);

  const handleOnActive = () => {
    fetchCurrentEvent();
    fetchCurrentPerformanceEvent();
    fetchLastAlive();
  };

  const handleOnIdle = () => {
    fetchCurrentEvent();
    fetchCurrentPerformanceEvent();
    fetchLastAlive();
    idleTimerRef.current.reset();
  };

  const onDowntimePopupHide = () => {
    if (widget.showPopupAtEventStatus === 'BOTH') {
      setShowDowntimePopup(false);
      return;
    }
    setDowntimePopupEvent(null);
    setShowDowntimePopup(false);
  };

  const onPerformancePopupHide = () => setPerformancePopupEvent(null);

  const onDefectPopupHide = () => setDefectPopupEvent(null);

  const onMachineOperatorPopupHide = () => setShowMachineOperatorPopup(false);

  const previousShiftRef = useRef();

  useEffect(() => {
    if (previousShiftRef.current && JSON.stringify(previousShiftRef.current) !== JSON.stringify(currentShift)) {
      setOperatorPopupEvent(currentShift);
      setShowMachineOperatorPopup(true);
    }
    previousShiftRef.current = currentShift;
  }, [currentShift]);

  useEffect(() => {
    currentEventRef.current = currentEvent;
  }, [currentEvent]);

  useEffect(() => {
    downtimePopupEventRef.current = downtimePopupEvent;
  }, [downtimePopupEvent]);

  // Fetch data every 5 minutes
  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchCurrentEvent();
      fetchCurrentPerformanceEvent();
      fetchLastAlive();
    }, FIVE_MINUTES);

    return () => clearInterval(intervalId);
  }, []);

  const { color, timeInMS, text } = getMachineStateInformations(
    widget.aliveDelay,
    currentMachine?.stopCauses,
    currentEvent,
    lastAlive,
    settings.defaultUnfilledStopCauseColor,
    showAlive,
    language,
  );

  const timer = formatDuration(timeInMS, {
    year: true, month: true, day: true, hourSeparator: ':', minSeparator: ':', sec: true,
  }, false, language, true);

  const width = widget.format.shape === 'circle' ? Math.min(w, h) : w;
  const height = widget.format.shape === 'circle' ? Math.min(w, h) : h;

  const operationLength = `${t('operation')}: ${machineParams && widget.shownMachineParams.includes('operation') && machineParams.operation}`.length;
  const operatorLength = `${t('operator')}: ${machineParams && widget.shownMachineParams.includes('operator') && machineParams.operator}`.length;
  const workOrderLength = `${t('workOrder')}: ${machineParams && widget.shownMachineParams.includes('workOrder') && machineParams.workOrder}`.length;
  const skuNumberLength = `${t('skuNumber')}: ${machineParams && widget.shownMachineParams.includes('skuNumber') && machineParams.skuNumber}`.length;
  const biggestParamLength = Math.max(operationLength, operatorLength, workOrderLength, skuNumberLength);

  const orderedParams = ['operator', 'workOrder', 'operation', 'skuNumber'];
  const sortedParams = orderedParams
    .filter(param => widget.shownMachineParams.includes(param) && (machineParams[param] || machineParams[param] === ''))
    .map(p => ({
      name: p,
      value: machineParams[p],
    }));

  const {
    paramsSize,
    timeSize,
  } = getMachineStateWidgetSize(width, height, widget.shownMachineParams.length, Math.min(biggestParamLength, 25));

  const timerFontSize = widget.contentSize || timeSize;
  const parametersFontSize = widget.contentSize || paramsSize;

  return (
    <JsxWidget
      dimension={{ x, y, w, h }}
      backgroundColor={color}
      shape={widget.format.shape}
      title={title}
      titleSize={widget.titleSize}
    >
      <IdleTimer
        ref={idleTimerRef}
        timeout={1000 * 90}
        onActive={handleOnActive}
        onIdle={handleOnIdle}
        debounce={250}
      />
      <MachineOperatorPopup
        show={!!operatorPopupEvent && !isInConfigurationMode && showMachineOperatorPopup
          && widget.showOperatorPopupAtShiftChange}
        onHide={onMachineOperatorPopupHide}
        machineParams={machineParams}
        machineId={widget.machineId}
      />
      <MachineEventPopup
        show={!!downtimePopupEvent && !isInConfigurationMode && showDowntimePopup}
        onHide={onDowntimePopupHide}
        event={downtimePopupEvent}
        machineId={widget.machineId}
        mandatoryStopCause={widget.mandatoryStopCause}
      />
      <PerformanceEventPopup
        show={!!performancePopupEvent && !isInConfigurationMode && showPerformancePopup}
        onHide={onPerformancePopupHide}
        event={performancePopupEvent}
        machineId={widget.machineId}
      />
      <DefectEventPopup
        show={!!defectPopupEvent && !isInConfigurationMode && showDefectPopup}
        onHide={onDefectPopupHide}
        event={defectPopupEvent}
        machineId={widget.machineId}
      />
      <div
        className="framed flex V"
        style={{ color: invertColor(color) }}
      >
        {machine ? (
          <>
            {widget.showTimer && (
              <div
                style={{
                  fontSize: `${timerFontSize}px`,
                }}
              >
                {timer}
              </div>
            )}
            {widget.showStopCause && (
              <div
                className="MachineStateWidgetParameter"
                style={{ fontSize: `${parametersFontSize}px` }}
              >
                {text}
              </div>
            )}
            {widget.shownMachineParams.length > 0 && (
              <div className={widget.format.shape === 'rect' ? 'MachineStateWidgetParameter' : 'MachineStateWidgetParameterCenter'}>
                {
                  sortedParams
                    .map(p => (
                      <MachineParameter
                        parameter={p}
                        fontSize={parametersFontSize}
                      />
                    ))
                }
              </div>
            )}
          </>
        ) : (
          <div style={{ fontSize: '30px' }}>{t('machineIsNotConfiguredOrDeleted')}</div>
        )}
      </div>
    </JsxWidget>
  );
};

MachineStateWidget.propTypes = {
  h: PropTypes.number.isRequired,
  w: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  widget: reducersTypes.topviews.widget.isRequired,
};

export default MachineStateWidget;
