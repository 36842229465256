import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, ColorPicker, Select, Input } from '@intelligenceindustrielle/react-ui-components';
import { EntriesForm, FileUpload, FontAwesome } from '~UI/index';
import { sortArray } from '~utils/sort';
import { RootState } from '~services/store';
import { showUpload } from '~utils/toast';
import { reduxOperations } from '~services/index';

interface Param {
  key: string;
  readOnly?: boolean;
  value: string;
}

const propTypes = {
  selectedObject: PropTypes.shape({
    buttonType: PropTypes.string,
    buttonColor: PropTypes.string,
    buttonText: PropTypes.string,
    popup: PropTypes.string,
    onClickTrigger: PropTypes.string,
    buttonParams: PropTypes.string,
    openOption: PropTypes.string,
    mediaURL: PropTypes.string,
  }),
};

const defaultProps = {
  selectedObject: {
    buttonType: 'triggerButton',
  },
};

const FormButton = ({ selectedObject }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const triggers = useSelector((state: RootState) => state.triggers);
  const popupFeature = useSelector((state: RootState) => state.settings.settings.featureToggles.features.popupButton);
  const forms = useSelector((state: RootState) => state.forms);
  const images = useSelector((state: RootState) => state.images.images);

  const parameters = selectedObject.buttonParams ? JSON.parse(selectedObject.buttonParams) : {};
  const params = selectedObject.buttonType === 'popupButton'
    ? Object.keys(parameters)
      .map(key => ({
        key,
        value: parameters[key].value,
        readOnly: parameters[key].readOnly,
      }))
    : Object.keys(parameters)
      .map(key => ({
        key,
        value: parameters[key],
      }));
  params.push({
    key: '',
    value: '',
  });

  const initializeMachineIdList = paramsArg => {
    const listOfIndexes: number[] = [];
    paramsArg.forEach((elem, index) => {
      if (elem && elem.key.toLowerCase() === 'machineid') {
        listOfIndexes.push(index);
      }
    });
    return listOfIndexes;
  };

  const initialList = initializeMachineIdList(params);

  const [buttonType, setButtonType] = useState(selectedObject.buttonType || 'triggerButton');
  const [buttonTextOrURL, setButtonTextOrURL] = useState(selectedObject?.buttonText || '');
  const [popup, setPopup] = useState(selectedObject.popup);
  const [onClickTrigger, setOnClickTrigger] = useState(selectedObject?.onClickTrigger || '');
  const [paramsState, setParams] = useState<Param[]>(params);
  const [openOption, setOpenOption] = useState(selectedObject?.openOption || '');
  const [mediaURL, setMediaURL] = useState(selectedObject?.mediaURL || '');
  const listOfIndexesToShow = initialList;

  const handleChangeType = buttonTypeArg => {
    setButtonType(buttonTypeArg);
    setPopup(buttonTypeArg === 'popupButton' ? selectedObject.popup || 'MachineWorkorderPopup' : '');
  };

  const handleOpenOptionChange = option => {
    if (option === openOption) {
      setOpenOption('');
    } else {
      setOpenOption(option);
    }
  };

  const handleChangePopup = e => {
    setPopup(e);
  };

  const handleRuleChange = e => {
    setOnClickTrigger(e);
  };

  const handleParamsChange = paramsArg => setParams(paramsArg);

  const getParamsValue = () => {
    const value = {};
    paramsState.forEach(param => {
      if (param.key !== '') {
        value[param.key] = buttonType === 'popupButton' ? {
          value: param.value,
          readOnly: param.readOnly,
        } : param.value;
      }
    });
    return JSON.stringify(value);
  };

  const handleFileSelect = (selectedFile, context) => {
    showUpload(
      reduxOperations.images.addImage(selectedFile)(dispatch),
      t('uploadingImage'),
      t('showSuccessAdded'),
    )
      .then(res => {
        if (typeof res.payload.data === 'string') {
          if (context === 'buttonTextOrURL') {
            setButtonTextOrURL(res.payload.data);
          } else if (context === 'mediaURL') {
            setMediaURL(res.payload.data);
          }
        }
      });
  };

  useEffect(() => {
    setButtonTextOrURL(selectedObject?.buttonText || '');
    setPopup(selectedObject.popup);
    setOnClickTrigger(selectedObject?.onClickTrigger || '');
    setParams(params);
  }, [buttonType]);

  const buttons = [
    { label: t('triggerButton'), value: 'triggerButton' },
    { label: t('popupButton'), value: 'popupButton' },
  ];

  const urlOptions = [
    { label: 'Popup', value: 'popup' },
    { label: t('redirection'), value: 'redirection' },
    { label: t('newTab'), value: 'newTab' },
  ];

  const trigger = triggers.find(trig => trig.id === onClickTrigger);
  const triggerOptions = sortArray('alphabetically', triggers, 'name').map(tr => ({ label: tr.name, value: tr.id }));

  return (
    <>
      <input name="buttonType" type="hidden" value={buttonType} />
      {popupFeature && (
        <>
          <div className="inputTitle">{t('type')}</div>
          <ButtonGroup
            buttons={buttons}
            onChange={handleChangeType}
            value={buttonType}
          />
        </>
      )}
      {buttonType === 'triggerButton' && (
        <>
          <div className="inputTitle">{t('color')}</div>
          <ColorPicker
            defaultValue={selectedObject.buttonColor}
            name="buttonColor"
          />
        </>
      )}
      <div className="inputTitle">
        {buttonType === 'infoButton' ? t('URL') : `${t('buttonText')} ${t('orImageUrl')}`}
      </div>
      <div className="fileContainer">
        <Input
          type="text"
          name="buttonText"
          className="fullwidth"
          value={buttonTextOrURL}
          onChange={setButtonTextOrURL}
          style={{ marginRight: '10px', flexGrow: 1 }}
        />
        <FileUpload
          onFileSelect={selectedFile => handleFileSelect(selectedFile, 'buttonTextOrURL')}
          images={images}
          image={buttonTextOrURL}
          setImage={setButtonTextOrURL}
        />
      </div>
      {
        buttonType === 'popupButton'
          && (
            <>
              <div className="inputTitle">Popup</div>
              <Select
                value={popup || 'MachineOperatorPopup'}
                name="popup"
                onChange={handleChangePopup}
                options={[
                  {
                    label: 'Machine Operator Popup',
                    value: 'MachineOperatorPopup',
                  },
                  {
                    label: 'Machine Workorder Popup',
                    value: 'MachineWorkorderPopup',
                  },
                  {
                    label: 'T Event Popup',
                    value: 'TransitEventPopup',
                  },
                  ...forms
                    .map(({ id, label }) => ({ label, value: id })),
                ]}
                placeholder={t('selectOption')}
              />
            </>
          )
      }
      <div className="breakLine" />
      {buttonType !== 'infoButton' && (
        <>
          {buttonType === 'popupButton'
            ? (
              <div className="inputTitle">
                {t('rule')}
                {popup !== 'TransitEventPopup' ? ` (${t('optional')})` : ''}
              </div>
            )
            : <div className="inputTitle">{t('rule')}</div>
          }
          <div className="ruleInput">
            <Select
              value={onClickTrigger}
              className="ruleSelect"
              name="onClickTrigger"
              onChange={handleRuleChange}
              options={triggerOptions}
              placeholder={t('selectOption')}
              style={{ flexGrow: 1 }}
            />
            {onClickTrigger && (
              <Link
                key="/config/ruleEdition/:id"
                to={`/config/ruleEdition/${onClickTrigger}`}
              >
                <button type="button" className="ruleButton">
                  {t('goToRule')}
                &nbsp;
                &nbsp;
                  <FontAwesome icon="external-link" />
                </button>
              </Link>
            )}
          </div>
        </>
      )}
      {
        trigger && !trigger.isActive && (
          <>
            <br />
            <span style={{ paddingLeft: '4px', color: 'red' }}>
              {t('ruleNotActive')}
            </span>
          </>
        )
      }
      <div className="inputTitle">{t('buttonParams')}</div>
      <EntriesForm
        params={paramsState}
        onChange={handleParamsChange}
        showReadOnly={buttonType === 'popupButton'}
        listOfIndexesToShow={listOfIndexesToShow}
      />
      <input
        type="hidden"
        name="buttonParams"
        value={getParamsValue()}
      />
      <div className="breakLine" />
      <input name="openOption" type="hidden" value={openOption} />
      <div className="inputTitle">{t('urlOpeningMethod')}</div>
      <ButtonGroup
        buttons={urlOptions}
        onChange={handleOpenOptionChange}
        value={openOption}
      />
      {openOption && (
        <>
          <div className="inputTitle">URL</div>
          <div className="fileContainer">
            <Input
              type="text"
              name="mediaURL"
              className="fullwidth"
              value={mediaURL}
              onChange={setMediaURL}
              style={{ marginRight: '10px', flexGrow: 1 }}
            />
            <FileUpload
              onFileSelect={selectedFile => handleFileSelect(selectedFile, 'mediaURL')}
              images={images}
              image={mediaURL}
              setImage={setMediaURL}
              options={{ popoverTop: true }}
            />
          </div>
        </>
      )}
    </>
  );
};

FormButton.propTypes = propTypes;
FormButton.defaultProps = defaultProps;

export default FormButton;
