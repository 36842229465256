import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import IdleTimer from 'react-idle-timer';
import { v4 as uuidv4 } from 'uuid';
import { reducersTypes } from '~services';
import { useShift } from '~utils/hooks';
import API from '~services/endpoints';
import { getDataRangeStartEnd } from '~utils/time';
import { FETCH_STATS_DELAY_MS } from '~utils/constants';
import { getSocket } from '~services/socket';

// Function to get a cookie by name
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function PromptTileMachine({
  height,
  width,
  tile,
}) {
  const [currentShift] = useShift(tile.machineId);
  const idleTimerRef = useRef(null);
  const socket = getSocket();
  const [randomUUID] = useState(uuidv4());

  const [completeUrl, setCompleteUrl] = useState('');

  useEffect(() => {
    // Log all cookies to inspect their content
    // console.log('All cookies:', document.cookie);

    let token = getCookie('token');
    // console.log('Cookie token:', token); // Debugging

    // If the token is not in the cookies, try retrieving it from localStorage
    if (!token) {
      token = localStorage.getItem('token');
      // console.log('localStorage token:', token); // Debugging
    }

    // If no token is found, handle the error
    if (!token) {
      console.error('No token found. Use a default value or handle the error.');
      token = 'default_token_for_debugging'; // Replace this with appropriate error handling
    }

    const baseUrl = process.env.NODE_ENV === 'production' ? `https://${window.location.hostname}` : 'http://localhost:3000';
    setCompleteUrl(`${tile.url}?url=${baseUrl}&channel=${randomUUID}&token=${token}`);
  }, [tile.url, randomUUID]);

  const { data: statistics, refetch } = useQuery({
    queryKey: ['fetchScorecardMachineStats', tile.machineId, tile.currentOperation, tile.currentSKUNumber, tile.currentWorkOrder, tile.intervalType, false],
    queryFn: () => {
      const intervalType = tile.intervalType || 'shift';
      if (!tile.machineId) {
        return null;
      }

      const filter = {
        currentOperation: tile.currentOperation,
        currentSKUNumber: tile.currentSKUNumber,
        currentWorkOrder: tile.currentWorkOrder,
      };
      if (intervalType !== 'always') {
        const { start, end } = getDataRangeStartEnd(intervalType, currentShift);
        filter.timestamp = {
          $gt: start,
          $lt: end,
        };
      }

      return API.getMachineStats(tile.machineId, filter);
    },
    refetchInterval: FETCH_STATS_DELAY_MS,
    keepPreviousData: true,
  });

  const handleOnActive = () => {
    refetch();
  };

  const handleOnIdle = () => {
    refetch();
    idleTimerRef.current.reset();
  };

  useEffect(() => {
    if (statistics) {
      socket.emit('updateStatistics', { uuid: randomUUID, statistics });
    }
  }, [statistics]);

  return (
    <>
      <IdleTimer
        ref={idleTimerRef}
        timeout={1000 * 90}
        onActive={handleOnActive}
        onIdle={handleOnIdle}
        debounce={250}
      />
      <div className="framed flex V">
        <iframe
          style={{
            border: 0,
            height,
            width,
            transformOrigin: '0 0',
            position: 'absolute',
            top: 0,
          }}
          title="iframeTile"
          scrolling="auto"
          src={completeUrl}
        >
          <p>Your browser does not support iframes</p>
        </iframe>
      </div>
    </>
  );
}

PromptTileMachine.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  tile: reducersTypes.dashboards.tile.isRequired,
};

export default PromptTileMachine;
